import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import publicComponents from '@/utils/publicComponents'
import elementPlusCop from '@/utils/element'
// 引入全局样式文件
import '@/assets/scss/index.scss'

const app = createApp(App)
publicComponents(app)
elementPlusCop(app)
app.use(store).use(router).mount('#app')
