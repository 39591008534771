'use strict'

export default function publicComponents (app) {
  const contexts = require.context('@/components/public', true, /./)
  contexts.keys().filter(item => item.split('/').length === 2).forEach(component => {
    const componentEntity = contexts(component).default || contexts(component)
    // 使用内置的组件名称 进行全局组件注册
    app.component(componentEntity.name, componentEntity)
  })
}
