<template>
  <div class="m-tab">
    <div class="m-tab-img">
      <img src="/img/logo.png" alt="" />
    </div>
    <div class="m-tab-view">
      <router-link
        class="m-tab-item"
        v-for="tab in routers"
        :key="tab.id"
        :to="{path: tab.path, query: {navId: tab.id}}"
      >
        <span class="tab-link">
          {{tab.title}}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { GetWsMneus } from '@/api/WsIndexDatasApi'
import { ref, getCurrentInstance } from 'vue'

const initEffect = () => {
  const routers = ref([])
  const init = async () => {
    const internalInstance = getCurrentInstance()
    const { data: { Data } } = await GetWsMneus(null)
    const arrRouters = []
    internalInstance.appContext.config.globalProperties.$router.options.routes.forEach(route => {
      if (route.path !== '/404') {
        Data.forEach(item => {
          if (item.name === route.title) {
            arrRouters.push({ ...route, id: item.id })
          }
        })
      }
    })
    routers.value = arrRouters
  }
  init()

  return { routers }
}
export default {
  name: 'Header',
  setup () {
    // 数据请求
    const { routers } = initEffect()
    return { routers }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.m-tab {
  position: fixed;
  @include flex-center(space-between, center);
  width: 100vw;
  height: 1rem;
  background-color: $nav-color;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
  padding: 0 2.6vw;
  &-img {
    > img {
      width: 80%;
    }
  }
  &-item {
    color: $color-text;
    cursor: pointer;
    transition: 0.2s all linear;
    margin: 0 .5rem;
    &:hover {
      color: $nav-color-active;
    }
  }
}

.header-active {
  position: relative;
  top: 0;
  opacity: 0;
  transition: all .5s;
  transform: translateY(-100%);
}
</style>
