<template>
  <div class="header-main">
    <Header id="header-wapper" />
  </div>
  <div class="header-wrapper-back"></div>
  <div class="m-garden">
    <div class="m-garden-wrapper" id="app-garden">
      <div class="m-garden-wrapper-ellipse m-garden-wrapper-ellipse-ellipse1"></div>
      <div class="m-garden-wrapper-ellipse m-garden-wrapper-ellipse-ellipse2"></div>
      <div class="m-garden-wrapper-ellipse m-garden-wrapper-ellipse-ellipse3"></div>
      <div class="m-garden-wrapper-ellipse m-garden-wrapper-ellipse-ellipse4"></div>
      <div class="m-garden-wrapper-ellipse m-garden-wrapper-ellipse-ellipse5"></div>
      <div class="m-garden-wrapper-ellipse m-garden-wrapper-ellipse-ellipse6"></div>
    </div>
  </div>
  <router-view :key="pathName" />
  <Footer v-if="isDom" />
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const isDom = ref(true)
    const pathName = ref('')
    const route = useRouter()
    route.afterEach((to, from) => {
      pathName.value = to.name
      if (to.name === 'Products') {
        isDom.value = false
      } else {
        isDom.value = true
      }
    })

    onMounted(() => {
      const domList = document.getElementById('app-garden').children
      const scrollFunc = (evt) => {
        let scrollValue = 0
        let a = 0
        const e = evt || window.event
        if (e.wheelDelta) { // IE/Opera/Chrome
          scrollValue = e.wheelDelta
        } else if (e.detail) { // Firefox
          scrollValue = e.detail
        }
        if (scrollValue > 0) {
          a -= 10
        } else {
          a += 10
        }
        domList.forEach(item => {
          const w = parseFloat(getComputedStyle(item).top)
          item.style.top = `${w + a}px`
        })
      }

      window.addEventListener('DOMMouseScroll', scrollFunc, false)
      window.onmousewheel = document.onmousewheel = scrollFunc
    })
    return { isDom, pathName }
  }
}
</script>

<style lang="scss" scoped>
.header-main {
  position: fixed;
  top: 0;
  z-index: 100;
}
.header-wrapper-back {
  z-index: 0;
  min-height: 1rem;
}
.m-garden {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background: linear-gradient(98.63deg,rgba(248,249,251,0.546) 20.59%,rgba(120,230,220,0) 89.33%);
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: -1;
    max-width: 19.2rem;
    &-ellipse {
      position: absolute;
      width: 6.99rem;
      height: 6.99rem;
      // border-radius: 51%;
      z-index: -1;
      transition: all .3s linear;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      -webkit-perspective: 1000;
      -moz-perspective: 1000;
      -ms-perspective: 1000;
      perspective: 1000;
      background-size: cover;
      &-ellipse1 {
        right: -1.9rem;
        top: 20rem;
        background: url('./assets/img/bg1.png') no-repeat center center;
      }
      &-ellipse2 {
        left: -2.1rem;
        top: 4.8rem;
        background: url('./assets/img/bg3.png') no-repeat center center;
      }
      &-ellipse3 {
        right: -1.38rem;
        top: 10.7rem;
        background: url('./assets/img/bg4.png') no-repeat center center;
      }
      &-ellipse4 {
        left: -2.32rem;
        top: 13.6rem;
        background: url('./assets/img/bg2.png') no-repeat center center;
      }
      &-ellipse5 {
        right: -1.38rem;
        top: 1.7rem;
        background: url('./assets/img/bg1.png') no-repeat center center;
      }
        &-ellipse6 {
        left: -2.38rem;
        top: 20.7rem;
        background: url('./assets/img/bg3.png') no-repeat center center;
        transform: rotate(-40.95deg);
      }
      // &-ellipse5 {
      //   right: -2.57rem;
      //   top: 24.7rem;
      //   background: linear-gradient(44.11deg,rgba(124,177,235,0.6) 8.94%,rgba(255,255,255,0) 86.73%);
      //   transform: rotate(-70.95deg);
      // }
      // &-ellipse6 {
      //   left: -2.73rem;
      //   top: 30.5rem;
      //   background: linear-gradient(44.11deg,rgba(245,198,39,0.6) 8.94%,rgba(255,255,255,0) 86.73%);
      //   transform: scale(0.95) rotate(-21.57deg);
      // }
      // &-ellipse7 {
      //   right: -3.07rem;
      //   top: 42.5rem;
      //   background: linear-gradient(44.11deg,rgba(139,233,223,0.75) 8.94%,rgba(255,255,255,0) 86.73%);
      //   transform: scale(0.85) rotate(-93.95deg);
      // }
      // &-ellipse8 {
      //   left: -2.43rem;
      //   top: 8.5rem;
      //   background: linear-gradient(44.11deg,rgba(124,177,235,0.6) 8.94%,rgba(255,255,255,0) 86.73%);
      //   transform: rotate(-21.95deg);
      // }
    }
  }
}
</style>
