<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :page-size="pageSize"
    :current-page="currentPage"
    layout="prev, pager, next, jumper"
    :total="total">
  </el-pagination>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
  name: 'Pagination',
  props: ['totalPage', 'current', 'pageSizeNum'],
  setup (props, { emit }) {
    console.log('%c 🍍 props: ', 'font-size:20px;background-color: #B03734;color:#fff;', props)
    const pagination = reactive({
      total: props.totalPage ?? 120,
      currentPage: props.current ?? 1,
      pageSize: props.pageSizeNuml ?? 10
    })

    const handleSizeChange = (size) => {
      console.log('%c 🍏 size: ', 'font-size:20px;background-color: #6EC1C2;color:#fff;', size)
    }

    const handleCurrentChange = (currentPage) => {
      console.log('%c 🍒 currentPage: ', 'font-size:20px;background-color: #6EC1C2;color:#fff;', currentPage)
    }
    console.log('%c 🥡 pagination: ', 'font-size:20px;background-color: #7F2B82;color:#fff;', (pagination))

    return { ...toRefs(pagination), handleCurrentChange, handleSizeChange }
  }
}
</script>

<style lang="scss" scoped>

</style>
