'use strict'
import { post } from '@/utils/request/https'

export const GetIndexData = (data, cbs, errHandle) => {
  return post('/api/WsIndexDatasApi/GetIndexData', data, cbs, errHandle)
}

export const GetWsMneus = (data, cbs, errHandle) => {
  return post('/api/WsIndexDatasApi/GetWsMneus', data, cbs, errHandle)
}

export const GetNewsPageData = (data, cbs, errHandle) => {
  return post('/api/WsIndexDatasApi/GetNewsPageData', data, cbs, errHandle)
}

export const GetNewsById = (data, cbs, errHandle) => {
  return post('/api/WsIndexDatasApi/GetNewsById', data, cbs, errHandle)
}

export const GetAttactmentByBusId = (data, cbs, errHandle) => {
  return post('/api/WsIndexDatasApi/GetAttactmentByBusId', data, cbs, errHandle)
}
