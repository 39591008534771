<template>
  <div class="m-footer">
    <div>
      <img src="/img/logo.png" alt="" />
    </div>
    <div class="m-footer-wrapper">
      <div class="m-footer-wrapper-item">
        <p class="m-footer-wrapper-item-title">公司业务</p>
        <p>
          <a>公司简介</a>
        </p>
        <p>
          <a>软件研发</a>
        </p>
        <p>
          <a>系统集成</a>
        </p>
      </div>
      <div class="m-footer-wrapper-item">
        <p class="m-footer-wrapper-item-title">公司新闻</p>
        <p>
          <a>公司动态</a>
        </p>
      </div>
      <div class="m-footer-wrapper-item">
        <p class="m-footer-wrapper-item-title">联系我们</p>
        <p>
          <a>电话：(+86) 028-86520520</a>
        </p>
        <p>
          <a>地址：四川省成都市武科西二路189号</a>
        </p>
      </div>
    </div>
  </div>
  <div class="m-footer-bottom">
    Copyright © yunlongtech.com All Rights Reserved 版权所有: 成都云隆科技有限公司 ｜
    <a class="footer-link" href="http://beian.miit.gov.cn" target="_blank">蜀ICP备14024262号-1 </a>
    公安部备案号：51010702001081
  </div>
</template>

<script>
export default {
  name: 'Footer',
  setup () {

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.m-footer {
  @include flex-center(space-between, self-start, wrap);
  position: relative;
  background-color: $nav-color;
  width: 100%;
  color: #fff;
  padding: .43rem 9vw .33rem;
  box-sizing: border-box;
  &-wrapper {
    display: flex;
    &-item {
      margin-right: 6vw;
      &-title {
        margin-bottom: .16rem;
        font-size: .22rem;
        line-height: .28rem;
      }
      a {
        font-size: .18rem;
        color: $color-text;
        cursor: pointer;
      }
    }
  }
  &-bottom {
    background: #3151a0;
    color: $color-text;
    text-align: center;
    font-size: .14rem;
    padding: .1rem 0;
  }
}
</style>
